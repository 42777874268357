// https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=D7CCC8&secondary.color=FFCCBC

$primary: 'primary';
$primary-dark: 'primary-dark';
$primary-light: 'primary-light';
$primary-light-transparent: 'primary-light-transparent';
$secondary: 'secondary';
$secondary-dark: 'secondary-dark';
$secondary-light: 'secondary-light';
$background-color: 'background-color';
$background-color-transparent: 'background-color-transparent';
$text-color: 'text-color';
$text-highlight: 'text-highlight';
$text-search-highlight: 'text-search-highlight';
$body-color: 'body-color';
$grad1: 'grad1';
$grad2: 'grad2';
$grad3: 'grad3';

$themes: (
    default: (primary: rgba(236, 239, 241, 0.8),
        primary-dark: rgba(186, 189, 190, 0.8),
        primary-light: rgba(255, 255, 255, 0.8),
        primary-light-transparent: rgba(252, 252, 255, 0.4),
        secondary: #b6e3ff,
        secondary-dark: #82b1ff,
        secondary-light: rgba(186, 189, 190, 0.7),
        background-color: rgb(252, 252, 255),
        background-color-transparent: rgba(252, 252, 255, 0.35),
        text-highlight: #d80808,
        text-search-highlight: #ffff00,
        text-color: #000000,
        grad1: rgba(110, 114, 220, 0.329),
        grad2: rgba(208, 35, 208, 0.208),
        grad3: rgba(225, 14, 46, 0.174),
        body-color: #000000),
    dark: (primary: rgba(38, 50, 56, 0.8),
        primary-dark: rgba(0, 10, 18, 0.8),
        primary-light: rgba(32, 33, 33, 0.8),
        primary-light-transparent: rgba(38, 50, 56,0.4),
        secondary: #37474f,
        secondary-dark: #102027,
        secondary-light: rgba(98, 114, 123, 0.7),
        background-color: #102027,
        background-color-transparent: rgba(17, 42, 52, 0.35),
        text-color: rgb(229, 225, 219),
        text-highlight: #eb7a7a,
        text-search-highlight: #ffff00,
        grad1: rgba(10, 19, 84, 0.625),
        grad2: rgba(39, 16, 31, 0.674),
        grad3: rgba(38, 16, 39, 0.752),
        body-color: #102027),
);


@mixin themed() {

    @each $theme,
    $map in $themes {
        .theme--#{$theme} & {
            $theme-map: () !global;

            @each $key,
            $submap in $map {
                $value: map-get(map-get($themes, $theme), '#{$key}');
                $theme-map: map-merge($theme-map, ($key: $value)) !global;
            }

            @content;
            $theme-map: null !global;
        }
    }
}

@function t($key) {
    @return map-get($theme-map, $key);
}