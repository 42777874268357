@use '../../../color_themes' as ct;


#card-controls.card .card-body{
    border-radius: .375rem;
    @include ct.themed() {background-color: ct.t(ct.$primary-light)}
}

#card-controls {
    border:0;
}