@use '../../color_themes' as ct;


.list-group.list-group-flush .list-group-item{
    @include ct.themed() {color: ct.t(ct.$text-color);}
    border: 0;
    background-color: rgba(0,0,0,0);

}

.toast-body{
    color:black;
}

