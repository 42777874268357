@use 'color_themes' as ct;
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
.theme--default {
    /* redefine theme colors for dark theme */
    $primary: #b6e3ff;
    $secondary: #b6e3ff;
    $danger: #d80808;
    @import "~bootstrap/scss/bootstrap";
}

.theme--dark {
        /* redefine theme colors for dark theme */
        $primary: #37474f;
        $secondary: #37474f;
        $danger: #eb7a7a;
        @import "~bootstrap/scss/bootstrap";
        color: #dbd7d1 !important;
        button {
            // color: #dbd7d1!important;
        }
        label {
            color: #dbd7d1!important;
        }
        .btn {
            // color: #dbd7d1!important;
        }
        .iconCurriculum {
            background-color: #ffffff;
        }

}

.background-transparent {
    @include ct.themed() {
        background-color: ct.t(ct.$background-color-transparent) !important;
    }
}

// Bootstrap and its default variables
// @import "node_modules/bootstrap/scss/bootstrap";


.main-container {
    // background-color: red;
    min-height: 100vh;
    @include ct.themed() {
        background: linear-gradient(
        to bottom right,
        ct.t(ct.$background-color)0%,
        ct.t(ct.$grad1),
        ct.t(ct.$grad2),
        ct.t(ct.$grad3),
        ct.t(ct.$background-color)100%
    );
        background-color: ct.t(ct.$background-color);
    }
}


.opacity-2 {
    background-color: rgba(255, 255, 255, 0) !important;
}

.card {
    border: 0px;
    
}

.card .card-body {
    @include ct.themed() {
        background-color: ct.t(ct.$primary-light-transparent)
    }
}



.accordion .card-header button {
    width: 100%;
}

#navigation-left {


    border-radius: .375rem;

    .list-group-item {
        @include ct.themed() {
            background-color: ct.t(ct.$primary);
            color: ct.t(ct.$text-color);
            border: 0;
        }
    }

    .list-group-item.active {
        @include ct.themed() {
            background-color: ct.t(ct.$secondary-light);
            color: ct.t(ct.$text-color);
        }
    }

    .list-group-item:hover {
        @include ct.themed() {
            background-color: ct.t(ct.$secondary-light);
            color: ct.t(ct.$text-color);
        }
    }
}

.customNavBar {
    @include ct.themed() {
        color: ct.t(ct.$text-color);
        background-color: ct.t(ct.$primary-light-transparent);
    }
}

.navbar-nav .nav-item button {
    @include ct.themed() {
        background-color: ct.t(ct.$primary);
        border-left: 5px solid ct.t(ct.$primary)
    }
}

.navbar-nav .nav-item button:hover {
    @include ct.themed() {
        background-color: ct.t(ct.$primary);
        border-left: 5px solid ct.t(ct.$secondary)
    }
}

.navbar-nav .nav-link {
    @include ct.themed() {
        color: ct.t(ct.$text-color);
    }
}

.navbar-nav .nav-item .active {
    @include ct.themed() {
        background-color: ct.t(ct.$primary);
        color: ct.t(ct.$text-color);
        border-left: 5px solid ct.t(ct.$secondary)
    }
}

.navbar-brand {
    @include ct.themed() {
        color: ct.t(ct.$text-color);
    }
}

#darkmodeswitch {
    @include ct.themed() {
        background-color: ct.t(ct.$secondary);
        border-color: ct.t(ct.$secondary);
    }
}

#darkmodeswitch:checked {
    @include ct.themed() {
        border-color: ct.t(ct.$secondary);
    }
}


body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
code {
font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
  
