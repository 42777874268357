@use '../../color_themes' as ct;

.text-highlight {
    @include ct.themed() {
        color: ct.t(ct.$text-highlight);
    }
}

.text-search-highlight {
    color: black;

    @include ct.themed() {
        background-color: ct.t(ct.$text-search-highlight);
    }
}

.curriculum-item.card {
    border: 0;
}

.accordion {
    margin: 0;
    width: 100%;

    .card-header {
        @include ct.themed() {
            background-color: ct.t(ct.$primary-light)
        }
    }

    .content {
        @include ct.themed() {
            background-color: ct.t(ct.$primary-light)
        }
    }

    .card-footer {
        @include ct.themed() {
            background-color: ct.t(ct.$primary-light)
        }
    }

}

/* .card-controls {
    margin: 0;
    width: 100%;
} */

.accordion .title {
    /* padding: 30px 30px; */
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    position: relative;
    /* font-size: 20px; */
    /* background: #1569a8; */
    margin-bottom: -1px;
    /* border-bottom: 1px solid #0e4671; */
    text-align: left;
}


.accordion .title .fa-chevron-down {
    /* font-size: 18px; */
    /* color: white; */
    -webkit-transition: -webkit-transform .3s ease-in-out;
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    /* position: absolute;
    right: 30px;
    font-family: monospace; */
}

.accordion .title.is-expanded {
    -webkit-transition: background .3s;
    transition: background .3s;
    /* background: #0e4671; */
}

.accordion .title.is-expanded .fa-chevron-down {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.accordion .content {
    overflow: hidden;
    max-height: 0;
    -webkit-transition: max-height .3s;
    transition: max-height .3s;
    margin: 0;
    padding: 0 30px;
    /* border: solid 1px #eeeeee; */
    /* border-top: 0; */
    /* background: #e8f4fc; */
}

.accordion .content p {
    /* padding: 30px 0; */
    margin: 0;
    opacity: 0;
    -webkit-transition: .3s;
    transition: .3s;
}

.accordion .content.is-expanded {
    max-height: 1500px;
    overflow: auto;
}

.accordion .content.is-expanded p {
    opacity: 1;
}

.notice-me {
    animation: tilt-shaking 0.4s;
}

@keyframes tilt-shaking {
    0% {
        transform: rotate(0deg);
    }

    16% {
        transform: rotate(4deg);
    }

    33% {
        transform: rotate(0deg);
        ;
    }

    50% {
        transform: rotate(-4deg);
        background-color: rgba(200, 29, 29, 0.641)
    }

    66% {
        transform: rotate(0deg);
    }

    82% {
        transform: rotate(4deg);
    }

    100% {
        transform: rotate(0deg);
    }
}